import { UserFeedback } from "models/userFeedback"
import { APIService } from "services/API"

const url = '/userFeedback'

export const userFeedbackService = {

    create: (userFeedback: Partial<UserFeedback>) => {
        return APIService.post<UserFeedback, UserFeedback>(url, userFeedback)
    }

}


