import { pushAlertMessage } from "components/alert";

export const hex_to_rgb = (hex: string) => {
    hex = hex.slice(0, 7)
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export const hexInverseBw = (hex: string) => {
    if (!hex) return "#000000";
    try {
        const rgb = hex_to_rgb(hex);
        if(isNullOrUndefined(rgb)) return "#000000"
        const luminance = (0.2126 * rgb["r"] + 0.7152 * rgb["g"] + 0.0722 * rgb["b"]);
        return (luminance < 140) ? "#ffffff" : "#000000";
    } catch (error) {
        return "#000000";
    }

}

export const shadeColor = (color: string, percent: number) => {

    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt(R * (100 + percent) / 100 as any);
    G = parseInt(G * (100 + percent) / 100 as any);
    B = parseInt(B * (100 + percent) / 100 as any);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    const RR = ((R.toString(16).length === 1) ? "0" + R.toString(16) : R.toString(16));
    const GG = ((G.toString(16).length === 1) ? "0" + G.toString(16) : G.toString(16));
    const BB = ((B.toString(16).length === 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
}

export const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const validURL = (str: string) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}


const getResizedImage = (
    contextRef: React.MutableRefObject<CanvasRenderingContext2D | null | undefined>,
    canvasRef: React.MutableRefObject<HTMLCanvasElement | null | undefined>,
    imageRef: React.RefObject<HTMLImageElement>) => {

    return new Promise<Blob | null>((resolve) => {

        if (!contextRef.current) return
        if (!canvasRef.current) return
        if (!imageRef.current) return

        const { current: ctx } = contextRef
        const { current: canvas } = canvasRef
        const { current: img } = imageRef


        const f = img.height / img.width;
        const newHeight = canvas.width * f;

        ctx.clearRect(0, 0, 150, 150)
        ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, newHeight);
        return canvas.toBlob((blob) => {
            resolve(blob)
        })
    })

}


const readFile = async (
    reader: FileReader,
    contextRef: React.MutableRefObject<CanvasRenderingContext2D | null | undefined>,
    canvasRef: React.MutableRefObject<HTMLCanvasElement | null | undefined>,
    imageRef: React.RefObject<HTMLImageElement>,
    callBackUpload: (resizedImg: Blob | null) => void
) => {
    if (!imageRef.current) return

    if (reader && reader.result) {
        const { current: img } = imageRef
        img.onload = async () => {
            const resizedImg = await getResizedImage(contextRef, canvasRef, imageRef)
            if (resizedImg)
                callBackUpload(resizedImg)
            else
                pushAlertMessage('error', 'Erro ao processar a imagem')
        }
        img.src = reader.result as string
    }
}

export const openUserImage = (
    contextRef: React.MutableRefObject<CanvasRenderingContext2D | null | undefined>,
    canvasRef: React.MutableRefObject<HTMLCanvasElement | null | undefined>,
    imageRef: React.RefObject<HTMLImageElement>,
    callBackUpload: (resizedImg: Blob | null) => void
) => {

    //Example
    // const contextRef = useRef<CanvasRenderingContext2D | null | undefined>()
    // const canvasRef = useRef<HTMLCanvasElement | null>()
    // const imageRef = useRef<HTMLImageElement>(null)

    // <img style={{ position: 'absolute', display: 'none' }} alt="hidden profile" ref={imageRef} src={""} />
    //     <canvas style={{ position: 'absolute', display: 'none' }} ref={(c) => {
    //         canvasRef.current = c
    //         contextRef.current = c?.getContext('2d')
    //     }} width="300" height="300">
    //     </canvas>
    const inputId = 'inputImageProfileAux'
    let input = document.querySelector(`#${inputId}`) as HTMLInputElement
    if (!input)
        input = document.createElement('input')
    const body = document.querySelector('body')
    if(isNullOrUndefined(body)) return
    input.type = 'file'
    input.id = inputId
    input.accept = "image/*"
    input.style.position = 'absolute'
    input.style.opacity = '0';
    input.style.display = 'none';
    body.appendChild(input);
    input.click()
    input.onchange = (event) => {
        setTimeout(() => {
            if (input.files && input.files.length) {
                const file = input.files[0];
                const reader = new FileReader();
                reader.addEventListener('load', () => readFile(reader, contextRef, canvasRef, imageRef, callBackUpload));
                reader.readAsDataURL(file);
            } else {
                pushAlertMessage('error', 'Erro ao carregar a imagem')
            }
        }, 300);
    }

}



export const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52);
export const randomRGB = () => {
    const num1 = randomNum()
    const num2 = randomNum()
    const num3 = randomNum()
    return {
        background: `rgb(${num1}, ${num2}, ${num3})`,
        border: `rgb(${num1}, ${num2}, ${num3}, 0.75)`
    }

};

export const isFalsy = (obj: any) => (
    obj === false ||
    obj === 0 ||
    obj === "" ||
    obj === undefined ||
    Number.isNaN(obj)
)
export const isTruthy = (obj: any) => !isFalsy(obj)
export const isNullOrUndefined = (object: any): object is null | undefined => {
    return object === null || object === undefined
}