import React from 'react'
import { Notification, NotificationProps, toaster } from 'rsuite';
import { RsRefForwardingComponent } from 'rsuite/esm/@types/common';



export interface IAlertMessage extends NotificationProps{
    message:string
}
const AlertMessage = React.forwardRef<RsRefForwardingComponent<"div", NotificationProps>, IAlertMessage>(({ message, type, ...rest }, ref) => {
    return (
      <Notification ref={ref} {...rest} type={type} header={type}>
        {message}
      </Notification>
    );
  });
  

export default AlertMessage


export const pushAlertMessage = (type:'info'|"success"|"warning"|"error", message:string) => {
    toaster.push(<AlertMessage type={type} message={message} />)
}