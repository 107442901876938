
import LayoutComponent from "components/layout";
import ListaLinkLayoutComponent from "components/listaLinkLayout";
import React, { Suspense, useMemo } from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import appTheme from "theme";
import { blockGoogleAnalytics, isLogged } from "utils/App";
import CookieConsent from "react-cookie-consent";
import Fallback from "components/fallback";


interface ILayoutWrapper {
  component: any
  onlyLogged?: boolean
  withoutLayout?: boolean
  bgColor?: string
}

interface IRoutes extends ILayoutWrapper {
  path:string
}

const routes:{[index:string]:IRoutes} = {
  listaLink: {
    path: "/:userName",
    withoutLayout: true,
    component: React.lazy(() => import('screens/listaLink'))
  },
  users: {
    path: "/users",
    onlyLogged: true,
    component: React.lazy(() => import('screens/users'))
  },
  register: {
    path: "/register",
    component: React.lazy(() => import('screens/register'))
  },
  cadastro: {
    path: "/cadastro",
    component: React.lazy(() => import('screens/register'))
  },
  login: {
    path: "/login",
    component: React.lazy(() => import('screens/login'))
  },
  forgotPassword: {
    path: "/forgotPassword",
    component: React.lazy(() => import('screens/forgotPassword'))
  },
  confirmEmail: {
    path: "/confirmEmail",
    component: React.lazy(() => import('screens/confirmEmail'))
  },
  terms: {
    path: "/terms",
    component: React.lazy(() => import('screens/terms'))
  },
  dashboard: {
    path: "/dashboard",
    onlyLogged: true,
    component: React.lazy(() => import('screens/dashboard')),
    bgColor:appTheme.secondaryColor
  },
  noLoggedHome: {
    path: "/",
    component: React.lazy(() => import('screens/noLoggedHome'))
  },
  socialLogin: {
    path: "/socialLogin",
    withoutLayout: true,
    component: React.lazy(() => import('screens/socialLogin'))
  }
}

const Router: React.FC<any> = (props) => {



  const LayoutWrapper = (props: ILayoutWrapper) => {
    const { component: Component, onlyLogged = false, withoutLayout: without = false, bgColor } = props;


    const returnedComponent = useMemo(() => {

      if (without) {

        return <ListaLinkLayoutComponent>
          <Suspense fallback={<Fallback/>}>
            <Component />
          </Suspense>
        </ListaLinkLayoutComponent>
      }


      if (onlyLogged) {
        if (isLogged())
          return <LayoutComponent bgColor={bgColor}>
            <Suspense fallback={<Fallback/>}>
                <Component />
            </Suspense>
          </LayoutComponent>
        else {
          return <Navigate to="/login" replace />
        }

      }

      return <LayoutComponent bgColor={bgColor}>
        <Suspense fallback={<Fallback/>}>
                <Component />
          </Suspense>
      </LayoutComponent>

    }, [Component, onlyLogged, without, bgColor])

    return <>{returnedComponent}</>

  }

  return (
    <BrowserRouter>
      <Routes>
        {Object.getOwnPropertyNames(routes).map((routeName: string) => {
          const keyRouteName = routeName as keyof typeof routes
          return <Route
          key={routes[keyRouteName].path}
          path={routes[keyRouteName].path}
          element={<LayoutWrapper
            withoutLayout={!!routes[keyRouteName].withoutLayout}
            onlyLogged={!!routes[keyRouteName].onlyLogged}
            component={routes[keyRouteName].component}
            bgColor={routes[keyRouteName].bgColor}
          />}
        />
        })}
      </Routes>
      <CookieConsent
        buttonText="Tudo bem."
        declineButtonText="Não concordo."
        declineButtonStyle={{
          border: '1px solid rgb(255 219 0 / 35%)',
          backgroundColor: 'rgb(255 255 255 / 0%)',
          color: 'rgb(255 219 0 / 35%)'
        }}
        onDecline={blockGoogleAnalytics}
        enableDeclineButton={true}
      >Este site usa cookies para melhorar a experiência do usuário.</CookieConsent>
    </BrowserRouter>
  )
}

export default Router
