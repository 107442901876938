import React, { PropsWithChildren, useMemo, useState } from 'react';
import { AppFooter, AppFooterFlag, AppFooterItem, ChildrenContainer, LayoutContainer, StyledNav, StyledNavbar, VersionContainer } from './styled';

import { MdLogin } from 'react-icons/md'
import { BiCog } from 'react-icons/bi'
import { BsInstagram, BsShare, BsFacebook } from 'react-icons/bs';
import { useLocation, useNavigate } from "react-router-dom";
import { STAGE, VERSION } from 'settings';
import { isLogged, logoutToken } from 'utils/App';
import { useAppDispatch } from 'store';
import { logout } from 'store/login.store';
import AppButton from 'components/appButton';
import AppLogo from 'components/appLogo';
import { useTranslation } from 'react-i18next';
import appTheme from 'theme';
import UserFeedbackComponent from 'components/userFeedback';
interface IHomeScreen extends PropsWithChildren<any> {
    withoutHeader?: boolean
    withoutFooter?: boolean
    bgColor?: string
}
export const LayoutComponent: React.FC<IHomeScreen> = (props) => {

    const { t } = useTranslation()
    const { 
        children, 
        withoutHeader = false, 
        withoutFooter = false, 
        bgColor = appTheme.background 
    } = props;
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const [stageVersion, setStageVersion] = useState<string>(STAGE)

    const loginOrLogout = () => {
        if (isLogged()) {
            logoutToken()
            dispatch(logout())
            navigate('/login')
        } else {
            navigate('/login')
        }
    }

    const gotToHome = () => {
        if (isLogged()) {
            return navigate(`/dashboard`)
        } else {
            return navigate(`/`)
        }
    }

    const redirect = (link: string) => {
        window.open(link, '_blank');
    }

    const isLogin = useMemo(() => {
        return location.pathname === '/login'
    }, [location])

    const toggleStageVersion = () => {
        setStageVersion(old => {
            if (old === STAGE)
                return VERSION
            return STAGE
        })
    }

    return <LayoutContainer data-cy="lyt-container" style={{ backgroundColor: bgColor }}>
        {isLogged() && <UserFeedbackComponent/>}
        {!withoutHeader
            ? <StyledNavbar>
                <VersionContainer onClick={toggleStageVersion}>
                    {stageVersion}
                </VersionContainer>
                <StyledNav>
                    <AppLogo onClick={gotToHome} />
                </StyledNav>
                {!isLogin &&
                    <>
                        <StyledNav pullRight>
                            <AppButton
                                id={`btn-go-${isLogged() ? 'logout' : 'login'}`}
                                text={isLogged() ? t('logout') : t('login')}
                                icon={<MdLogin />}
                                onClick={() => { loginOrLogout() }}
                            />
                        </StyledNav>
                        {isLogged() &&
                            <StyledNav pullRight>
                                <AppButton
                                    id="btn-go-profile"
                                    text='Perfil'
                                    icon={<BiCog />}
                                    onClick={() => {
                                        navigate('/users')
                                    }}
                                />
                            </StyledNav>
                        }
                        {!isLogged() &&
                            <StyledNav pullRight>
                                {t('doYouHaveAAccount')}
                            </StyledNav>
                        }
                    </>}
            </StyledNavbar>
            : <></>}
        <ChildrenContainer style={{ backgroundColor: bgColor }}>
            {children}
            {!withoutFooter
                ? <AppFooter>
                    <AppFooterItem>
                        Todos os direitos reservados a Lista.Link 2022.
                    </AppFooterItem>
                    <AppFooterItem>
                        <AppFooterFlag />
                    </AppFooterItem>
                    <AppFooterItem>
                        <BsInstagram onClick={() => redirect('https://www.instagram.com/lista.linkbr/')} style={{ cursor: 'pointer' }} size={25} />
                        <BsShare style={{ cursor: 'pointer' }} size={25} />
                        <BsFacebook onClick={() => redirect('https://www.facebook.com/listalinkbr')} style={{ cursor: 'pointer' }} size={25} />
                    </AppFooterItem>
                </AppFooter>
                : <></>}
        </ChildrenContainer>

    </LayoutContainer>
}


export default LayoutComponent