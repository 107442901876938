import { useEffect } from 'react';
import './App.css';
import Router from './router';
import store from 'store'
import { Provider } from 'react-redux'
import appTheme from 'theme';
import { ThemeProvider } from 'styled-components';
import 'lang/i18n';
import 'animate.css';
import ReactGA from "react-ga4";
import { getCookieConsentValue } from "react-cookie-consent";
import { blockGoogleAnalytics } from 'utils/App';
import { getVersion } from 'services/version';
import { VERSION } from 'settings';
import { isNullOrUndefined } from 'utils';


function App() {

  const cookieConsentValue = getCookieConsentValue()
  if (cookieConsentValue === 'true' || cookieConsentValue === undefined) {
    if (!isNullOrUndefined(process.env.REACT_APP_GA))
      ReactGA.initialize(process.env.REACT_APP_GA);
  } else {
    blockGoogleAnalytics()
  }

 
  useEffect(() => {
    getVersion().then(version => {
      console.log('env', process.env.NODE_ENV)
      console.log('server version', version)
      console.log('app version', VERSION)
      if (version !== VERSION) {
        window.location.reload()
      }
    })
  }, [])

  return (
    <Provider store={store}>
      <ThemeProvider theme={appTheme}>
        <Router />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
