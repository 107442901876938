import React from 'react'
import { BsInstagram, BsWhatsapp } from 'react-icons/bs'
import { Button, ButtonProps } from 'rsuite'
import styled, { css } from 'styled-components'
import { shadeColor } from 'utils'

/**
 * @enum {string}
 * 
 */
export enum ButtonType {
    ONE = '1',
    TWO = '2',
    THREE = '3',
    FOUR = '4',
    FIVE = "5",
    SIX = "6",
    SEVEN = "7",
    EIGHT = "8",
    NINE = "9",
    TEN = "10",
    /**
     * @deprecated WPP1 is deprecated, use SocialType instead
     * it is only used in the old version of the app
     */
    WPP1 = 'WPP1',
    /**
     * @deprecated INST1 is deprecated, use SocialType instead
     * it is only used in the old version of the app
     */
    INST1 = 'INST1'
}

export enum ButtonSocialType {
    WhatsApp = 'WA',
    Instagram = 'IG'
}

interface IButtonByType extends ButtonProps {
    className?: string
    buttonType: ButtonType,
    buttonSocialType?: ButtonSocialType,
    buttonBackgroundColor: string,
    buttonColor: string,
    text: string
}
const PVButton: React.FC<IButtonByType> = (props) => {
    const {
        buttonType,
        className,
        text,
        buttonBackgroundColor,
        buttonColor,
        ...restProps } = props
    return <Button
        data-tp={`${buttonType}`}
        data-cy={`cpn-link-${text}`}
        {...restProps}
        className={className}
    >
        {props.buttonSocialType === ButtonSocialType.WhatsApp &&
            <div className='iconContainer' data-cy="BsWhatsapp">
                <BsWhatsapp />
            </div>
        }
        {props.buttonSocialType === ButtonSocialType.Instagram &&
            <div className='iconContainer' data-cy="BsInstagram">
                <BsInstagram />
            </div>
        }
        {text}
    </Button>
}

export const ButtonByType = styled(PVButton)`
/* width:100%; */

    position: relative;
    background-color: ${props => props.buttonBackgroundColor};
    color: ${props => props.buttonColor};
    box-sizing: border-box;
    width: 325px;

    @media only screen and (max-width: 325px) {
                        min-width: 100%;
                    }

    & .iconContainer {
        position: absolute;
        left: 15px;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 30px;
    }


    :hover{
        background-color: ${props => shadeColor(props.buttonBackgroundColor, 10)};
        color: ${props => shadeColor(props.buttonColor, 10)}; 
    }

    :active{
        background-color: ${props => props.buttonBackgroundColor};
        color: ${props => props.buttonColor}; 
    }

    :focus{
        background-color: ${props => props.buttonBackgroundColor};
        color: ${props => props.buttonColor}; 
    }

    ${props => {

        switch (props.buttonType) {
            case ButtonType.ONE:
                return css`
                    box-shadow: 1px 1px 5px -2px #000000;
                    min-height: 65px;
                    font-size: ${props => props.theme.defaultBtnFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    white-space: normal;

                `
            case ButtonType.TWO:
                return css`
                    min-height: 65px;
                    font-size: ${props => props.theme.defaultBtnFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    white-space: normal;
                    border: 1px solid #0000000f;

                `

            case ButtonType.THREE:
                return css`
                    min-height: 65px;
                    font-size: ${props => props.theme.defaultBtnFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    white-space: normal;
                    border-radius: 4px;
                    border: 1px solid ${_ => shadeColor(props.buttonBackgroundColor, -40)};
                    border-bottom: 6px solid ${_ => shadeColor(props.buttonBackgroundColor, -40)};
                    border-right: 6px solid ${_ => shadeColor(props.buttonBackgroundColor, -40)};
                `

            case ButtonType.FOUR:
                return css`
                    box-shadow: 7px 7px 1px 1px ${_ => shadeColor(props.buttonBackgroundColor, -80)};
                    border: 1px solid ${_ => shadeColor(props.buttonBackgroundColor, -60)};
                    border-radius: 0px;
                    min-height: 65px;
                    font-size: ${props => props.theme.defaultBtnFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    white-space: normal;
                `
            case ButtonType.FIVE:
                return css`
                    border: 1px solid ${_ => shadeColor(props.buttonBackgroundColor, -60)};
                    border-radius: 0px;
                    min-height: 65px;
                    font-size: ${props => props.theme.defaultBtnFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    white-space: normal;
                `
            case ButtonType.SIX:
                return css`
                    box-shadow: 1px 1px 5px -2px #000000;
                    border-radius: 50px;
                    min-height: 65px;
                    font-size: ${props => props.theme.defaultBtnFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    white-space: normal;
                `
            case ButtonType.SEVEN:
                return css`
                    min-height: 65px;
                    border-radius: 50px;
                    font-size: ${props => props.theme.defaultBtnFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    white-space: normal;
                    border: 1px solid #0000000f;

                `
            case ButtonType.EIGHT:
                return css`
                    min-height: 65px;
                    border-radius: 50px;
                    font-size: ${props => props.theme.defaultBtnFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    white-space: normal;
                    border: 1px solid ${_ => shadeColor(props.buttonBackgroundColor, -40)};
                    border-bottom: 6px solid ${_ => shadeColor(props.buttonBackgroundColor, -40)};
                    border-right: 6px solid ${_ => shadeColor(props.buttonBackgroundColor, -40)};
                `
            case ButtonType.NINE:
                return css`
                    box-shadow: 7px 7px 1px 1px ${_ => shadeColor(props.buttonBackgroundColor, -80)};
                    border: 1px solid ${_ => shadeColor(props.buttonBackgroundColor, -60)};
                    border-radius: 0px;
                    min-height: 65px;
                    font-size: ${props => props.theme.defaultBtnFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    border-radius: 50px;
                    white-space: normal;
                `
            case ButtonType.TEN:
                return css`
                    border: 1px solid ${_ => shadeColor(props.buttonBackgroundColor, -60)};
                    border-radius: 50px;
                    min-height: 65px;
                    font-size: ${props => props.theme.defaultBtnFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    white-space: normal;
                `
                case ButtonType.WPP1:
                return css`
                    color: #fff;
                    background: linear-gradient(0deg, #2e9921 0%, #5ee357 100%);
                    border-radius: 6px;
                    min-height: 65px;
                    font-size: ${props => props.theme.defaultFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    text-shadow: 1px 1px 1px #00000090;
                    white-space: normal;
                    padding: 10px 40px;

                    :hover {
                        color: #fff;
                    }

                    :focus {
                        color: #fff;
                    }
                `
            case ButtonType.INST1:
                return css`
                    color: #fff;
                    background: linear-gradient(270deg, #f5843c 0%, #d7397a 55%, #5959ce 100%);
                    border-radius: 6px;
                    min-height: 65px;
                    font-size: ${props => props.theme.defaultFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    text-shadow: 1px 1px 1px #00000090;
                    white-space: normal;
                    padding: 10px 40px;

                    :hover {
                        color: #fff;
                    }

                    :focus {
                        color: #fff;
                    }
                `
            default:
                return css`
                    box-shadow: 1px 1px 5px -2px #000000;
                    min-height: 65px;
                    font-size: ${props => props.theme.defaultFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    white-space: normal;

                `
        }

    }}

    ${props => {
        switch (props.buttonSocialType) {
            case ButtonSocialType.WhatsApp:
                return css`
                    color: #fff;
                    background: linear-gradient(0deg, #2e9921 0%, #5ee357 100%);
                    border-bottom-color: #1f4a1a;
                    border-right-color: #1f4a1a;
                    border-top-color: #1f4a1a;
                    border-left-color: #1f4a1a;
                    font-size: ${props => props.theme.defaultFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    text-shadow: 1px 1px 1px #00000090;
                    white-space: normal;

                    :hover {
                        color: #fff;
                    }

                    :focus {
                        color: #fff;
                    }
                `
            case ButtonSocialType.Instagram:
                return css`
                    color: #fff;
                    background: linear-gradient(270deg, #f5843c 0%, #d7397a 55%, #5959ce 100%);
                    border-bottom-color: #4a0622;
                    border-right-color: #4a0622;
                    border-top-color: #4a0622;
                    border-left-color: #4a0622;
                    font-size: ${props => props.theme.defaultFontSize}px;
                    font-weight: ${props => props.theme.defaultFontBold};
                    text-shadow: 1px 1px 1px #00000090;
                    white-space: normal;


                    :hover {
                        color: #fff;
                    }

                    :focus {
                        color: #fff;
                    }
                `
            default:
                return css``
        }

    }}


`