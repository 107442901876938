import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { Link } from "models/link"
import { LinkStatistic } from "models/linkStatistic"
import { linksStatisticService } from "services/linksStatistic"

interface LinksStatisticState {
    entities: LinkStatistic[]
    loading: boolean,
    error?: any
}

export const clearLinksStatisticError = createAction('users/clearLinksStatisticError')



export const createLinksStatisticStatistic = createAsyncThunk(
    'linksStatistic/create',
    async (link: Link, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const createdLinksStatistic = await linksStatisticService.create({
                linkId:link.id
            })
            return createdLinksStatistic
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const getAllLinksStatistic = createAsyncThunk(
    'linksStatistic/getAll',
    async (_, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const linksStatistic = await linksStatisticService.getAll()
            return linksStatistic
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

const initialState: LinksStatisticState = {
    entities: [],
    loading: false,
}

export const linksStatisticReducer = createReducer(initialState, (builder) => {


    builder.addCase(clearLinksStatisticError, (state, action) => {
        state.loading = false
        state.error = undefined
    })

    builder.addCase(getAllLinksStatistic.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(getAllLinksStatistic.fulfilled, (state, action) => {
        state.entities = (action.payload)
        state.loading = false
        state.error = undefined
    })
    builder.addCase(getAllLinksStatistic.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })


}
)
