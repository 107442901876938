import React from 'react'
import { AppLogoContainer } from './styled'


interface IAppLogo {
    onClick?:() => void
}
const AppLogo:React.FC<IAppLogo> = (props) =>{

    const { onClick = () => {
        //TODO
    } } = props

    return <AppLogoContainer onClick={onClick}>
    </AppLogoContainer>
}

export default AppLogo