import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { User, UserChangePassword } from "models/user"
import { usersService } from "services/users"

interface UsersState {
    entity?: User;
    loading: boolean,
    error?: any,
    message?: any
}

export const clearUserError = createAction('users/clearUserError')

export const createUser = createAsyncThunk(
    'users/createUser',
    async (user: User, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            await usersService.createUser(user)
            return true as any
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const forgotPassword = createAsyncThunk(
    'users/forgotPassword',
    async (userOrEmail: string, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const result = await usersService.forgotPassword(userOrEmail)
            return result.message
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const confirmEmail = createAsyncThunk(
    'users/confirmEmail',
    async (validationCode: string, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const result = await usersService.confirmEmail(validationCode)
            return result.message
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const editUser = createAsyncThunk(
    'users/editUser',
    async (user: User, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const editedUser = await usersService.editUser(user)
            return editedUser
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const getUser = createAsyncThunk(
    'users/getUser',
    async (_, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const user = await usersService.getUser()
            return user
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const changePassword = createAsyncThunk(
    'users/changePassword',
    async (user: UserChangePassword, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            await usersService.changePassword(user)
            return true
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

const initialState: UsersState = {
    loading: false,
}

export const usersReducer = createReducer(initialState, (builder) => {
    builder.addCase(clearUserError, (state, action) => {
        state.loading = false
        state.error = undefined
    })
    builder.addCase(createUser.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(createUser.fulfilled, (state, action) => {
        state.loading = false
        state.error = undefined
    })
    builder.addCase(createUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })


    builder.addCase(changePassword.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false
        state.error = undefined
    })
    builder.addCase(changePassword.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })

    builder.addCase(forgotPassword.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false
        state.error = undefined
        state.message = action.payload
    })
    builder.addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })

    builder.addCase(confirmEmail.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(confirmEmail.fulfilled, (state, action) => {
        state.loading = false
        state.error = undefined
        state.message = action.payload
    })
    builder.addCase(confirmEmail.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })

    builder.addCase(getUser.fulfilled, (state, action) => {
        state.entity = action.payload;
        state.loading = false
        state.error = undefined
    })

    builder.addCase(editUser.fulfilled, (state, action) => {
        state.entity = action.payload;
        state.loading = false
        state.error = undefined
    })

    builder.addCase(editUser.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })

    builder.addCase(editUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })

}
)
