import { GoogleObj } from "models/authServices/google";
import { UserLogin } from "models/login";
import { User } from "models/user"
import { APIService } from "services/API"

const url = '/login'

export const loginService = {

    login: (user: UserLogin) => {
        return APIService.post<User,User>(url, user)
    },
    googleLogin: (user: GoogleObj) => {
        return APIService.post<User,User>(`${url}/google`, user)
    }

}


