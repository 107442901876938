import styled from 'styled-components'


export const AppLogoContainer = styled.div`
    width: 45px;
    height: 45px;
    border-radius: 100px;
    background-image: ${ _ => `url(${process.env.PUBLIC_URL}/assets/logo-bg-green.jpeg)`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
`;

