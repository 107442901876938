import { AiOutlineLoading } from 'react-icons/ai';
import styled from 'styled-components'
import { AppButtonAppearanceEnum } from '.';

export const AppButtonIcon = styled.div`
    display:flex;
    flex:1;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
`;

export const PVButton = (props:any) => {
    return <button data-cy={props.id ?? props.name} {...props}/>
}

export const StyledAppButton = styled(PVButton)`
    background-color: ${props => props.appearance === AppButtonAppearanceEnum.onlyBorder ? 'transparent' : props.theme.button};
    border: ${props => props.appearance === AppButtonAppearanceEnum.onlyBorder ? `1px solid ${props.theme.buttonBorder}`  : `1px solid ${props.theme.buttonBorder}` };
    color: ${props => props.theme.buttonText};
    font-weight: ${props => props.theme.defaultFontBold};
    border-radius: ${props => props.theme.defaultBorderRadius};
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => props.theme.defaultBtnFontSize}px;
    cursor: pointer;
`;

export const StyledLoading = styled(AiOutlineLoading)`

    animation: spin 1s linear infinite;
    margin: 0px 5px;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

`