import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { GoogleObj } from "models/authServices/google"
import { UserLogin } from "models/login"
import { User } from "models/user"
import { loginService } from "services/login"
import { managerStorage } from "services/storage"
import { STORAGE_TOKEN_KEY } from "settings"

interface LoginState {
    entity?: User
    loading: boolean,
    error?: any
}

export const clearLoginError = createAction('users/clearLoginError')
export const logout = createAction('users/logout')

export const login = createAsyncThunk(
    'login',
    async (user: UserLogin, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const loggedUser = await loginService.login(user)
            const storage = managerStorage();
            storage.setItem(STORAGE_TOKEN_KEY, loggedUser.token ?? "")
            return (loggedUser)
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const googleLogin = createAsyncThunk(
    'login',
    async (user: GoogleObj, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const loggedUser = await loginService.googleLogin(user)
            const storage = managerStorage();
            storage.setItem(STORAGE_TOKEN_KEY, loggedUser.token ?? "")
            return (loggedUser)
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

const initialState: LoginState = {
    loading: false,
}

export const loginReducer = createReducer(initialState, (builder) => {
    builder.addCase(clearLoginError, (state, action) => {
        state.loading = false
        state.error = undefined
    })
    builder.addCase(login.pending, (state, action) => {
        state.entity = undefined
        state.loading = true
        state.error = undefined
    })
    builder.addCase(login.fulfilled, (state, action) => {
        state.entity = action.payload
        state.loading = false
        state.error = undefined
    })
    builder.addCase(login.rejected, (state, action) => {
        state.entity = undefined
        state.loading = false
        state.error = action.payload
    })
}
)
