import { Dictionary } from "lodash"
import { TopLinkUserStatisticVO, UserStatistic } from "models/userStatistic"
import { APIService } from "services/API"

const url = '/userStatistic'

export const userStatisticService = {

    create: (user: string) => {
        return APIService.post<UserStatistic, UserStatistic>(`${url}/free/`, {
            user
        })
    },
    get: () => {
        return APIService.get<UserStatistic, UserStatistic>(url)
    },
    getVisitorStatistics: () => {
        return APIService.get<UserStatistic[], UserStatistic[]>(`${url}/visitorStatistics`)
    },
    getTopLinksStatistics: () => {
        return APIService.get<Dictionary<TopLinkUserStatisticVO[]>, Dictionary<TopLinkUserStatisticVO[]>>(`${url}/topLinksStatistics`)
    }

}


