import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { ButtonType } from "components/listaLink/buttons/buttonByType"
import { Link } from "models/link"
import { linksService } from "services/links"

interface LinksState {
    entities: Link[]
    loading: boolean,
    error?: any
}

export const clearLinkError = createAction('users/clearLinkError')

const setCurrentLinkType = (link:Link, links: Link[]) => {
    if(links.length){
        if(!link.type){
            const types = links.map(link => link.type)
            const linkType = types[0] ?? ButtonType.ONE   
            link.type = linkType

        }
    }
}

export const createLink = createAsyncThunk(
    'links/createLink',
    async (link: Link, thunkAPi) => {
        const { rejectWithValue, getState } = thunkAPi
        try {

            const state = getState() as { linksReducer:LinksState }
            const links = state.linksReducer.entities
            setCurrentLinkType(link, links)
            link.order = links.length+1
            
            const createdLink = await linksService.create(link)
            return createdLink
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const editLink = createAsyncThunk(
    'links/editLink',
    async (link: Link, thunkAPi) => {
        const { rejectWithValue, getState } = thunkAPi
        try {

            const state = getState() as { linksReducer:LinksState }
            const links = state.linksReducer.entities
            setCurrentLinkType(link, links)

            const editedLink = await linksService.edit(link.id ?? 0, link)
            return editedLink
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const editAllLink = createAsyncThunk(
    'links/editAllLink',
    async (links: Link[], thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            return await linksService.editAllLinkOrder(links)
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)


export const editAllLinkType = createAsyncThunk(
    'links/editAllLinkType',
    async (buttonType: string, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const editedLinks = await linksService.editAllLinkType(buttonType)
            return editedLinks
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const updateAllLinkColor = createAsyncThunk(
    'links/updateAllLinkColor',
    async (color: string, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const editedLinks = await linksService.updateAllLinkColor(color)
            return editedLinks
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const getAllLinks = createAsyncThunk(
    'links/getAll',
    async (_, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const links = await linksService.getAll()
            return links
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const getAllLinksByUserName = createAsyncThunk(
    'links/getAllLinksByUserName',
    async (userName:string, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const links = await linksService.getAllLinksByUserName(userName)
            return links
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const removeLink = createAsyncThunk(
    'links/removeLink',
    async (linkId:number, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            await linksService.removeLink(linkId)
            return linkId
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)


const initialState: LinksState = {
    entities: [],
    loading: false,
}

export const linksReducer = createReducer(initialState, (builder) => {


    builder.addCase(removeLink.fulfilled, (state, action) => {
        state.entities = state.entities.filter(f => f.id !== action.payload)
        state.loading = false
        state.error = undefined
    })

    builder.addCase(editLink.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(editLink.fulfilled, (state, action) => {
        
        const editedLink = action.payload as Link
        const currentLink = state.entities.find(f => f.id?.toString() === editedLink.id?.toString() )
        if(currentLink){
            currentLink.name = editedLink.name
            currentLink.color = editedLink.color
            currentLink.link = editedLink.link
            currentLink.type = editedLink.type
            currentLink.socialType = editedLink.socialType
        }

        state.loading = false
        state.error = undefined
    })
    builder.addCase(editLink.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })


    builder.addCase(editAllLink.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(editAllLink.fulfilled, (state, action) => {
        state.entities = action.payload
        state.loading = false
        state.error = undefined
    })
    builder.addCase(editAllLink.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })


    builder.addCase(clearLinkError, (state, action) => {
        state.loading = false
        state.error = undefined
    })
    builder.addCase(createLink.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(createLink.fulfilled, (state, action) => {
        state.entities.push(action.payload)
        state.loading = false
        state.error = undefined
    })
    builder.addCase(createLink.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })

    builder.addCase(editAllLinkType.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(editAllLinkType.fulfilled, (state, action) => {
        state.entities = action.payload
        state.loading = false
        state.error = undefined
    })
    builder.addCase(editAllLinkType.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })

    builder.addCase(getAllLinks.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(getAllLinks.fulfilled, (state, action) => {
        state.entities = (action.payload)
        state.loading = false
        state.error = undefined
    })
    builder.addCase(getAllLinks.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })


    builder.addCase(getAllLinksByUserName.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(getAllLinksByUserName.fulfilled, (state, action) => {
        state.entities = (action.payload)
        state.loading = false
        state.error = undefined
    })
    builder.addCase(getAllLinksByUserName.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })



    builder.addCase(updateAllLinkColor.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(updateAllLinkColor.fulfilled, (state, action) => {
        state.entities = action.payload
        state.loading = false
        state.error = undefined
    })
    builder.addCase(updateAllLinkColor.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })

}
)
