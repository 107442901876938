import { User, UserChangePassword } from "models/user"
import { APIService } from "services/API"

const url = '/users'

export const usersService = {

    createUser: (user: User) => {
        return APIService.post(url, user)
    },
    getUser: () => {
        return APIService.get<User, User>(url)
    },
    changePassword: (user: UserChangePassword) => {
        return APIService.patch(`${url}/changePassword`, user)
    },
    forgotPassword: (userOrEmail: string) => {
        return APIService.patch<{message:string}, {message:string}>(`${url}/free/forgotPassword`, {user:userOrEmail})
    },
    confirmEmail: (validationCode: string) => {
        return APIService.patch<{message:string}, {message:string}>(`${url}/free/confirmEmail`, {validationCode})
    },
    editUser:(user: User) =>{
    
        const _user: any = user
        delete _user.email
        delete _user.user
        delete _user.password

        return APIService.put<User, User>(url, _user)
    }


}


