
import { PropsWithChildren } from 'react'
import styled from 'styled-components'

interface ILayoutContainer{
    className?:string
    backgroundColor:string
    backgroundImage:string
}
export const PVLayoutContainer:React.FC<PropsWithChildren<ILayoutContainer>> = (props) => {

    const { children, className } = props
    return <div data-cy="cpn-lyt-ll-container" className={className}>
        {children}
    </div>
}

export const LayoutContainer = styled(PVLayoutContainer)`
    
    flex-direction: column;
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: ${props => props.backgroundColor};
    background-image: ${props => props.backgroundImage};
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

`



export const ChildrenContainer = styled.div`

    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: auto;
    min-height: calc(100% - 60px);
    

`
