import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { UserFeedback } from "models/userFeedback"
import { userFeedbackService as service } from "services/userFeedback"

interface UserFeedbackState {
    entities: UserFeedback[]
    loading: boolean,
    error?: any
}

export const clearUserFeedbackError = createAction('users/clearUserFeedbackError')

export const createUserFeedback = createAsyncThunk<UserFeedback, Partial<UserFeedback>>(
    'userFeedback/createUserFeedback',
    async (userFeedback: Partial<UserFeedback>, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const createdLink = await service.create(userFeedback)
            return createdLink
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)


const initialState: UserFeedbackState = {
    entities: [],
    loading: false,
}

export const userFeedbackReducer = createReducer(initialState, (builder) => {




    builder.addCase(clearUserFeedbackError, (state, action) => {
        state.loading = false
        state.error = undefined
    })


    builder.addCase(createUserFeedback.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(createUserFeedback.fulfilled, (state, action) => {
        state.entities.push(action.payload)
        state.loading = false
        state.error = undefined
    })
    builder.addCase(createUserFeedback.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })

})
