import { managerStorage } from "services/storage"
import { STORAGE_TOKEN_KEY } from "settings"
import { parseJwt } from "utils"


export const logoutToken = () => {
    const storage = managerStorage()
    storage.removeItem(STORAGE_TOKEN_KEY)
    return false
}

export const isLogged = () => {

    const storage = managerStorage()
    const token = storage.getItem(STORAGE_TOKEN_KEY)
    if(!token) return false

    const decodedToken = parseJwt(token)
    const { exp, id, name, userName } = decodedToken

    const expiredToken = (exp * 1000) < new Date().getTime()
    if(expiredToken) {
        return logoutToken()
    }

    if(!name || !userName || !id) {
        return logoutToken()
    }

    return true
}

export const getTokenUserName = () => {

    const storage = managerStorage()
    const token = storage.getItem(STORAGE_TOKEN_KEY)
    if(!token) return {tokenName:'', tokenUserName:''}

    const decodedToken = parseJwt(token)
    const { name, userName } = decodedToken
    

    return {tokenName: name, tokenUserName: userName}
}


export const isHomol = () => {
    return !!(process.env.REACT_APP_NODE_ENV === 'homol')
}

export const isProd = () => {
    return !!(process.env.REACT_APP_NODE_ENV === 'production')
}

export const isDesenv = () => {
    return !!(process.env.REACT_APP_NODE_ENV === 'development')
}

export const blockGoogleAnalytics = () =>{
    (window as any)[`ga-disable-${process.env.REACT_APP_GA ?? ''}`] = true;
}