import React, { useState } from 'react'
import { FeedbackButtonContainer, FeedbackFormHeader, FeedbackHeaderSubTitle, FeedbackHeaderTitle, FeedbackStyledForm, UserFeedbackContainer, UserFeedbackInnerContainer, UserFeedbackTag } from './styled'
import { VscFeedback } from 'react-icons/vsc'
import { Divider, Form, FormInstance, Input, Message, Radio, RadioGroup, Schema } from 'rsuite'
import { useAppDispatch, useAppSelector } from 'store';
import AppButton from 'components/appButton';
import { useTranslation } from 'react-i18next';
import { clearUserFeedbackError, createUserFeedback } from 'store/userFeedback.store';
import { UserFeedback } from 'models/userFeedback';
import { pushAlertMessage } from 'components/alert';
import { isTruthy } from 'utils';


const model = Schema.Model({
    type: Schema.Types.NumberType().isRequired('o Tipo é Obrigatório'),
    description: Schema.Types.StringType().isRequired('a descrição é Obrigatório'),
});

const Textarea = React.forwardRef((props:any, ref:any) => <Input 
{...props} as="textarea" rows={3} ref={ref}
/>);

const UserFeedbackComponent: React.FC<Record<string, never>> = (props) => {

    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [visible, setVisible] = useState(false)
    const error = useAppSelector(state => state.userFeedbackReducer.error)
    const loading = useAppSelector(state => state.userFeedbackReducer.loading)
    const formRef = React.useRef<FormInstance>(null);
    const [, setFormError] = React.useState({});
    const [formValue, setFormValue] = React.useState({
        type: 2,
        description: '',
    });

    const toggleVisible = () => {
        setVisible(old => !old)
    }

    const handleSubmit = async () => {

        dispatch(clearUserFeedbackError())

        if (!formRef?.current?.check) return
        if (!formRef?.current?.check()) return

        const newUserFeedback: Partial<UserFeedback> = {
            type: formValue.type,
            description: formValue.description
        }

        const success = await dispatch(createUserFeedback(newUserFeedback)).unwrap()
        if (isTruthy(success.id)) {
            setVisible(old => !old)
            setFormValue({
                type:2,
                description:''
            })
            pushAlertMessage('success', 'Obrigado pelo seu Feedback, ele será analisado com muito carinho.')
        }

    };
   

    return <UserFeedbackContainer
        visible={visible}
    >
        <UserFeedbackTag onClick={toggleVisible}>
            <VscFeedback size={30} />
        </UserFeedbackTag>
        <UserFeedbackInnerContainer>

            <FeedbackFormHeader>
                <FeedbackHeaderTitle>Deseja nos dar um feedback?</FeedbackHeaderTitle>
            </FeedbackFormHeader>
            <Divider style={{width:'100%', margin:'10px 0px'}} />
            <FeedbackStyledForm
                ref={formRef as any}
                onChange={setFormValue as any}
                onCheck={setFormError}
                formValue={formValue}
                model={model}
            >
                <Form.Group style={{width:'100%', display:'flex', flexDirection:'column'}} controlId="type">
                    <FeedbackHeaderSubTitle>Qual o tipo do feedback?</FeedbackHeaderSubTitle>
                    <Form.Control style={{display:'flex', flexDirection:'row'}} name="type" accepter={RadioGroup}>
                        <Radio value={2}>Sugestão</Radio>
                        <Radio value={1}>Problema</Radio>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="description">
                    <FeedbackHeaderSubTitle>Qual o seu feedback?</FeedbackHeaderSubTitle>
                    <Form.Control 
                            name="description" 
                            accepter={Textarea} />
                </Form.Group>
                {error && <Message type="error">{error.message.toString()}</Message>}
                <Form.Group>
                    <FeedbackButtonContainer>
                        <AppButton
                            style={{width:150}}
                            loading={loading}
                            text={t('send')}
                            onClick={handleSubmit}
                        />
                    </FeedbackButtonContainer>
                </Form.Group>
            </FeedbackStyledForm>

        </UserFeedbackInnerContainer>
    </UserFeedbackContainer>
}

export default UserFeedbackComponent