import { PropsWithChildren } from 'react';
import { Form } from 'rsuite';
import styled from 'styled-components'

interface IUserFeedbackContainer{
    className?:string,
    visible: boolean
}
const PVUserFeedbackContainer:React.FC<PropsWithChildren<IUserFeedbackContainer>> = (props) => {

    const { className, children } = props
    return <div className={className}>
        {children}
    </div>
}

export const UserFeedbackContainer = styled(PVUserFeedbackContainer)`
    position: absolute;
    top: 80px;
    left: ${props => props.visible ? '-5px' : '-400px' };
    height: 460px;
    width: 400px;
    transition: left .5s;
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 450px) {
        width: 310px;
        left: ${props => props.visible ? '0px' : '-310px'}; 
    }

`;

export const UserFeedbackInnerContainer = styled.div`

    
    height: 100%;
    background-color: ${props => props.theme.background};
    border: 1px solid ${props => props.theme.inputBorderColor};
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-start ;
    align-items: center;
    padding: 20px;
    width: 400px;
    overflow-x: hidden;
    overflow-y: auto;

    @media only screen and (max-width: 450px) {
        width: 310px;
        padding: 20px 20px;
    }
`;

export const UserFeedbackTag = styled.div`
    
    position: absolute;
    right: -35px;
    height: 35px;
    width: 40px;
    top:10px;
    padding-right: 5px;
    z-index: 2;
    border: 1px solid ${props => props.theme.secondaryColor};
    background-color: ${props => props.theme.button};
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    & svg {
        color: ${props => props.theme.buttonText};
    }


`;


export const FeedbackStyledForm = styled(Form)`

    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;

    & .rs-radio-inline{
        margin-left: 5px
    }

    @media only screen and (max-width: 450px) {
        padding: 0px
    }


`

export const FeedbackButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;


export const FeedbackFormHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 80px;
    

`


export const FeedbackHeaderTitle = styled.span`
   font-size: ${props => props.theme.defaultFontSizeTitle}px;
   color: ${props => props.theme.text};
   font-weight: ${props => props.theme.defaultFontBold};
   text-align: center;

   @media only screen and (max-width: 750px) {
    font-size: ${props => props.theme.defaultFontSizeTitle-5}px;
    }

`

export const FeedbackHeaderSubTitle = styled.span`
   font-size: ${props => props.theme.defaultFontSize}px;
   color: ${props => props.theme.text};
`