import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { pushAlertMessage } from "components/alert";
import { UserImage } from "models/userImage";
import { userImagesService } from "services/userImages"

interface UserImagesState {
    entity?: UserImage;
    loading: boolean,
    error?: any
}

export const clearUserError = createAction('users/clearUserImagesError')

export const createUserImages = createAsyncThunk(
    'users/createUserImages',
    async (img: Blob, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const form = new FormData();
            form.append('profileFile',img)
            const result = await userImagesService.createUserImage(form)
            pushAlertMessage('success', 'Imagem atualizada com sucesso')
            return result
        } catch (error:any) {
            pushAlertMessage('error', 'Erro ao atualizada a imagem')
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)


export const getUserImages = createAsyncThunk(
    'users/getUserImages',
    async (_, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const user = await userImagesService.getUserImage()
            return user
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const getImagesByUserName = createAsyncThunk(
    'users/getImagesByUserName',
    async (userName:string, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const user = await userImagesService.getImagesByUserName(userName)
            return user
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)


const initialState: UserImagesState = {
    loading: false,
}

export const userImagesReducer = createReducer(initialState, (builder) => {
    builder.addCase(clearUserError, (state, action) => {
        state.loading = false
        state.error = undefined
    })


    builder.addCase(getUserImages.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })

    builder.addCase(getUserImages.fulfilled, (state, action) => {
        state.entity = action.payload.profileImage;
        state.loading = false
        state.error = undefined
    })

    builder.addCase(getUserImages.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })

    builder.addCase(getImagesByUserName.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })

    builder.addCase(getImagesByUserName.fulfilled, (state, action) => {
        state.entity = action.payload.profileImage;
        state.loading = false
        state.error = undefined
    })

    builder.addCase(getImagesByUserName.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })

    builder.addCase(createUserImages.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })

    builder.addCase(createUserImages.fulfilled, (state, action) => {
        state.entity = action.payload;
        state.loading = false
        state.error = undefined
    })

    builder.addCase(createUserImages.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })


}
)
