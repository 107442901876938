import { LinkStatistic } from "models/linkStatistic"
import { APIService } from "services/API"

const url = '/linksStatistic'

export const linksStatisticService = {

    create: (linkStatistic: Partial<LinkStatistic>) => {
        return APIService.post<LinkStatistic, LinkStatistic>(`${url}/free/`, linkStatistic)
    },
    getAll: () => {
        return APIService.get<LinkStatistic[], LinkStatistic[]>(url)
    },
    createSync: (linkStatistic: Partial<LinkStatistic>) => {
        const xmlHttp = new XMLHttpRequest();

        xmlHttp.open('POST', `${process.env.REACT_APP_URL_API ?? ''}${url}/free/`, false);
        const data = new FormData();
        data.append('linkId', linkStatistic.id?.toString() ?? '');
        xmlHttp.send(data);
        return xmlHttp.responseText;
    }

}


