import React from 'react'
import { AppButtonIcon, StyledAppButton, StyledLoading } from './styled';


export enum AppButtonAppearanceEnum {
    primary,
    onlyBorder
}
interface IAppButton {
    text: string
    name?: string
    id?: string
    onClick: () => void
    type?: string
    style?: React.CSSProperties
    icon?: React.ReactElement
    iconPosition?:'left'|'right'
    loading?: boolean
    disabled?: boolean
    appearance?: AppButtonAppearanceEnum
}


const AppButton: React.FC<IAppButton> = (props) => {

    const { 
        text, 
        onClick, 
        style, 
        icon, 
        iconPosition = 'right', 
        loading = false, 
        disabled = false,
        appearance =  AppButtonAppearanceEnum.primary
    
    } = props

    const _onclick = () => {
        if(disabled) return

        if(onClick) onClick()
    }

    return <StyledAppButton 
        id={props.id} 
        name={props.name} 
        appearance={appearance} 
        style={style ?? {} as React.CSSProperties} 
        data-cy={props.id}
        onClick={_onclick}>
        {!!icon && iconPosition === 'left' &&
            <AppButtonIcon>
                {icon}
            </AppButtonIcon>
        }
        {text}
        {!!icon && iconPosition === 'right' &&
            <AppButtonIcon>
                {icon}
            </AppButtonIcon>
        }
        {!!loading && <StyledLoading/>}
    </StyledAppButton>
}

export default AppButton;