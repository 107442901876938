import React, { useMemo } from 'react';
import { ChildrenContainer, LayoutContainer } from './styled';


import { isLogged } from 'utils/App';
import { useAppSelector } from 'store';
import { isNullOrUndefined } from 'utils';


export const ListaLinkLayoutComponent: React.FC<any> = (props) => {

    const { children } = props;

    const userStyle = useAppSelector(state => state.usersReducer.entity?.userStyle?.value)
    const noLoggedUserStyle = useAppSelector(state => state.userStylesReducer.entity?.value)

    const currentStyles = useMemo(() => {

        if (isLogged() && !isNullOrUndefined(userStyle)) {
            return userStyle
        } else if (!isNullOrUndefined(noLoggedUserStyle)) {
            return noLoggedUserStyle
        }

        return {
            backgroundColor: '#ffffff',
            backgroundImage: '#ffffff'
        }

    }, [userStyle, noLoggedUserStyle])



    return <LayoutContainer 
        backgroundColor={currentStyles.backgroundColor}
        backgroundImage={currentStyles.backgroundImage}
    >
        <ChildrenContainer>
            {children}
        </ChildrenContainer>

    </LayoutContainer>
}


export default ListaLinkLayoutComponent