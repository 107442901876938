import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { UserStyle } from "models/userStyles"
import { userStylesService } from "services/userStyles"

interface UserStylesState {
    entity?: UserStyle
    loading: boolean,
    error?: any
}

export const clearUserStylesError = createAction('users/clearUserStylesError')

export const getAllUserStylesByUserName = createAsyncThunk(
    'userStyles/getAllUserStylesByUserName',
    async (userName:string, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const styles = await userStylesService.getAllUserStylesByUserName(userName)
            return styles
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const updateUserStyle = createAsyncThunk(
    'userStyle/update',
    async (userStyle: Partial<UserStyle>, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const updatedUserStyle = await userStylesService.update(userStyle)
            return updatedUserStyle
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

const initialState: UserStylesState = {
    loading: false,
}

export const userStylesReducer = createReducer(initialState, (builder) => {
    
    builder.addCase(updateUserStyle.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(updateUserStyle.fulfilled, (state, action) => {
        state.loading = false
        state.error = undefined
    })
    builder.addCase(updateUserStyle.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })

    builder.addCase(getAllUserStylesByUserName.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(getAllUserStylesByUserName.fulfilled, (state, action) => {
        state.entity = (action.payload)
        state.loading = false
        state.error = undefined
    })
    builder.addCase(getAllUserStylesByUserName.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })
}
)
