import { AiOutlineLoading } from 'react-icons/ai';
import styled from 'styled-components'


export const FallbackContainer = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    flex:1;
    width: 100%;
    background-color: ${props => props.theme.background};

`;

export const StyledLoading = styled(AiOutlineLoading)`

    animation: spin 1s linear infinite;
    margin: 0px 5px;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

`
