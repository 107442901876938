


export const managerStorage = (isLocal = true) =>{

    let storage = sessionStorage
    if(isLocal)
        storage = localStorage

    return {
        setItem: (keyName:string, value:string) =>{
            return storage.setItem(keyName, value)
        },
        getItem: (keyName:string) =>{
            return storage.getItem(keyName)
        },
        removeItem: (keyName:string) =>{
            return storage.removeItem(keyName)
        }
    }


}
