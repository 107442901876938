import { Link } from "models/link"
import { APIService } from "services/API"

const url = '/links'

export const linksService = {

    create: (link: Link) => {
        return APIService.post<Link, Link>(url, link)
    },
    editAllLinkType: (linkType:string) => {
        return APIService.put<Link[], Link[]>(`${url}/editAllLinkType/${linkType}`)
    },
    edit: (linkId:number, link: Link) => {
        return APIService.put<Link, Link>(`${url}/${linkId}`, link)
    },
    editAllLinkOrder: (links: Link[]) => {
        return APIService.put<Link[], Link[]>(`${url}/editAllLinkOrder`, links)
    },
    getAll: () => {
        return APIService.get<Link[], Link[]>(url)
    },
    getAllLinksByUserName: (userName:string) => {
        return APIService.get<Link[], Link[]>(`${url}/free/${userName}`)
    },
    removeLink: (linkId:number) => {
        return APIService.delete<Link, Link>(`${url}/${linkId}`)
    },
    updateAllLinkColor: (color:string) => {
        return APIService.put<Link[], Link[]>(`${url}/updateAllLinkColor`, {color})
    },

}


