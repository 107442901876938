import axios, { AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import moment from 'moment';
import qs from 'qs';
import { managerStorage } from 'services/storage';
import { STORAGE_TOKEN_KEY } from 'settings';

export enum HttpResponseCode {
    Undefined = '000',
    BadRequest = '400',
    Unauthorized = '401',
    Forbidden = '403',
    NotFound = '404',
    Timeout = '408',
    Conflict = '409',
    InternalServerError = '500'

}

export type HttpError = {
    type: HttpResponseCode,
    status: any,
    message: string,
    stack?: string,
    data?: any,
    memberNames?: string[],
}


export const initHTTPService = (urlApi: string, requestTimeout:number) => {
    const HTTPService:AxiosInstance = axios.create({
        baseURL: urlApi,
        timeout: requestTimeout
    });

    HTTPService.interceptors.request.use(
        (config: InternalAxiosRequestConfig) => {
            
            config.headers = (
                config.headers ? config.headers : new AxiosHeaders()
            ) as AxiosHeaders;
                
            if (!config.headers.get("X-Requested-With"))
                config.headers['X-Requested-With'] = 'XMLHttpRequest';
            if (!config.headers.get("Content-Type"))
                config.headers['Content-Type'] = 'application/json';

            const token = managerStorage().getItem(STORAGE_TOKEN_KEY)
            if(token)
                config.headers['x-access-token'] = token;

            config.paramsSerializer = {
                serialize: (params: any) => {
                    return qs.stringify(params, {
                        encodeValuesOnly: true,
                        arrayFormat: 'comma',
                        serializeDate: (date: Date) => {
                            return moment(date).format('YYYY-MM-DDTHH:mm:ss')
                        },
                    })
                }
            }

            return config;
        },
        (error: any) => {
            return Promise.reject(error);
        }

    );


    HTTPService.interceptors.response.use(
        (response: AxiosResponse) => {
            return response.data;
        }, (error: any) => {
            if (!error.response) {
                // TODO networkError actions
                return Promise.reject({ type: HttpResponseCode.Timeout, message: 'NetworkError', stack: error.stack, status: error.status } as HttpError);
            }
            else {

                const message = typeof(error.response.data) === "string" ? error.response.data : undefined

                if (error.response.status === 401) {
                    // TODO 401 actions
                    return Promise.reject({ type: HttpResponseCode.Unauthorized, message: message ?? 'http401', status: error.stack, data: error.response.data  } as HttpError);
                } else if (error.response.status === 404) {
                     // TODO 404 actions
                    return Promise.reject({ type: HttpResponseCode.NotFound, message: message ?? 'http404', status: error.stack, memberNames: [error.response.config.url], data: error.response.data  } as HttpError);
                } else if (error.response.status === 409) {
                     // TODO 409 actions
                    return Promise.reject({ type: HttpResponseCode.Conflict, message: message ?? 'http409', status: error.stack, memberNames: [error.response.config.url], data: error.response.data  } as HttpError);
                } else if (error.response.status === 400) {
                    // TODO 400 actions
                    return Promise.reject({ type: HttpResponseCode.BadRequest, message: message ?? 'http409', status: error.stack, memberNames: [error.response.config.url], data: error.response.data  } as HttpError); 
                } else if (error.response.status === 500) {
                    // TODO 500 actions
                    return Promise.reject({ type: HttpResponseCode.InternalServerError, message: message ?? 'http500', status: error.stack, memberNames: [error.response.config.url], data: error.response.data  } as HttpError); 
                } else  {
                    return Promise.reject({ type: error.response.status, message: message ?? 'defaultTextError', data: error.response.data });
                }
            }
        }
    );
    const { get, post, put, delete: del } = HTTPService;

    HTTPService.get = async <T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
        // TODO middleware
        return get(url, config);
    }

    HTTPService.post = async <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> => {
        // TODO middleware
        return post(url, data, config);
    }

    HTTPService.put = async <T = any, R = AxiosResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R> => {
        // TODO middleware
        return put(url, data, config);
    }

    HTTPService.delete = <T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
         // TODO middleware
        return del(url, config);
    }

    return { HTTPService };
}
