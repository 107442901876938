import { useState } from "react"

const appTheme = {
    primaryColor: '#C1D4BD',
    secondaryColor: '#105e0a',
    tertiaryColor:'#edfded',
    details:'#D9D9D9',
    background:'#ffffff',
    inputBackground: '#D9D9D9',
    inactiveInputBackground: '#f7f7fa',
    inputBorderColor:'#c5c5c5',
    text:'#525552',
    secondaryText:'#ffffff',
    tertiaryText:'#2c2c2c',
    linkText:'#4075ff',
    highlightedTex:'#105E0A',
    button: '#CDE623',
    buttonBorder: '#C1D4BD',
    inactiveButton: 'rgba(205, 230, 35, 0.4)',
    buttonText:'#105E0A',
    inactiveButtonText: 'rgba(16, 94, 10, 0.4)',
    defaultShadowColor: '#000',
    defaultDetailFontSize: 12,
    defaultFontSize: 18,
    defaultBtnFontSize: 16,
    defaultFontSizeLargeTitle: 18+22,
    defaultFontSizeTitle: 18+12,
    defaultFontBold: 700,
    defaultBorderRadius: '10px'
}


// const appThemePurple = {
//     primaryColor: '#7d45a9',
//     secondaryColor: '#53048f',
//     details:'#ffffff',
//     background:'#ffffff',
//     inputBackground: '#D9D9D9',
//     inactiveInputBackground: '#f7f7fa',
//     inputBorderColor:'#53048f',
//     text:'#594560',
//     secondaryText:'#ffffff',
//     tertiaryText:'#ffffff',
//     linkText:'#4075ff',
//     highlightedTex:'#53048f',
//     button: '#e2e3eb',
//     inactiveButton: 'rgba(226, 227, 235, 0.4)',
//     buttonText:'#53048f',
//     inactiveButtonText: '#53048f40',
//     defaultDetailFontSize: 12,
//     defaultFontSize: 18,
//     defaultBtnFontSize: 15,
//     defaultFontSizeLargeTitle: 18+22,
//     defaultFontSizeTitle: 18+12,
//     defaultFontBold: 700,
//     defaultBorderRadius: '10px'
// }

export type AppTheme = typeof appTheme

export default appTheme

export const useAppTheme = () =>{

    const [appTheme, setAppTheme] = useState()

    return {
        appTheme, setAppTheme
    }

}


declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends AppTheme {
  }
}

