import { ResponseUserImage, UserImage } from "models/userImage"
import { APIService } from "services/API"

const url = '/userImages'

export const userImagesService = {

    createUserImage: (user: FormData) => {
        return APIService.post<UserImage, UserImage>(url, user, {
            headers: { 'Content-Type': 'application/octet-stream' }
        })
    },
    getUserImage: () => {
        return APIService.get<ResponseUserImage, ResponseUserImage>(url)
    },
    getImagesByUserName: (userName:string) => {
        return APIService.get<ResponseUserImage, ResponseUserImage>(`${url}/free/${userName}`)
    },

}



