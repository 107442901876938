import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { Dictionary } from "lodash"
import { TopLinkUserStatisticVO, UserStatistic } from "models/userStatistic"
import { userStatisticService } from "services/userStatistic"

interface UserStatisticState {
    entity: UserStatistic
    visitorStatistics: UserStatistic[]
    topLinksStatistics: Dictionary<TopLinkUserStatisticVO[]>
    loading: boolean,
    error?: any
}

export const clearUserStatisticError = createAction('users/clearUserStatisticError')



export const createUserStatistic = createAsyncThunk(
    'userStatistic/create',
    async (user: string, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const createdUserStatistic = await userStatisticService.create(user)
            return createdUserStatistic
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const getAllUserStatistic = createAsyncThunk(
    'userStatistic/getAll',
    async (_, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const UserStatistic = await userStatisticService.get()
            return UserStatistic
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)


export const getVisitorStatistics = createAsyncThunk(
    'userStatistic/getVisitorStatistics',
    async (_, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const userStatistic = await userStatisticService.getVisitorStatistics()
            return userStatistic
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

export const getTopLinksStatistics = createAsyncThunk(
    'userStatistic/getTopLinksStatistics',
    async (_, thunkAPi) => {
        const { rejectWithValue } = thunkAPi
        try {
            const userStatistic = await userStatisticService.getTopLinksStatistics()
            return userStatistic
        } catch (error:any) {
            const returnValue = error.data
            return rejectWithValue(returnValue)
        }

    }
)

const initialState: UserStatisticState = {
    entity: {} as UserStatistic,
    visitorStatistics: [],
    topLinksStatistics: {} as Dictionary<TopLinkUserStatisticVO[]>,
    loading: false,
}

export const userStatisticReducer = createReducer(initialState, (builder) => {


    builder.addCase(clearUserStatisticError, (state, action) => {
        state.loading = false
        state.error = undefined
    })

    builder.addCase(getAllUserStatistic.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(getAllUserStatistic.fulfilled, (state, action) => {
        state.entity = (action.payload)
        state.loading = false
        state.error = undefined
    })
    builder.addCase(getAllUserStatistic.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })



    builder.addCase(getVisitorStatistics.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(getVisitorStatistics.fulfilled, (state, action) => {
        state.visitorStatistics = (action.payload)
        state.loading = false
        state.error = undefined
    })
    builder.addCase(getVisitorStatistics.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })



    builder.addCase(getTopLinksStatistics.pending, (state, action) => {
        state.loading = true
        state.error = undefined
    })
    builder.addCase(getTopLinksStatistics.fulfilled, (state, action) => {
        state.topLinksStatistics = (action.payload)
        state.loading = false
        state.error = undefined
    })
    builder.addCase(getTopLinksStatistics.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
    })


}
)
