import { Navbar, Nav } from 'rsuite'
import styled from 'styled-components'
import { isHomol } from 'utils/App'



export const StyledNavbar = styled(Navbar)`
    background-color: ${props => isHomol() ? 'red' : props.theme.primaryColor};
    border-bottom-left-radius: ${props => props.theme.defaultBorderRadius};
    border-bottom-right-radius: ${props => props.theme.defaultBorderRadius};
    box-shadow: 0px 1px 9px -4px ${props => props.theme.defaultShadowColor};
    height: 50px;
`
export const StyledNav = styled(Nav)`

    color: ${props => props.theme.tertiaryText};
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 0px 5px;
`


export const LayoutContainer = styled.div`
    
    position: 'relative';
    flex-direction: column;
    display: flex;
    height: 100vh;
    width: 100vw;


`



export const ChildrenContainer = styled.div`

    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: auto;
    margin-top: 4px;
    min-height: calc(100% - 60px);

    

`

export const UserStyleContainer = styled.div`

    position: absolute;
    right: 10px;
    top: 10px;
    background-color: ${props => props.theme.background};
    border-radius: 50px;
    padding: 5px;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
`

export const AppFooter = styled.div`
    color: ${props => props.theme.tertiaryText};
    background-color: ${props => props.theme.primaryColor};
    flex: 1;
    display: flex;
    width: 100%;
    padding: 5px;
    max-height: 50px;

`

export const AppFooterItem = styled.div`
    flex: 0.33;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    line-height: 14px;
`

export const AppFooterFlag = styled.div`
    height: 40px;
    width:  53px;
    background-image: ${ _ => `url(${process.env.PUBLIC_URL}/assets/bandeiraPE.jpg)`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 7px;
    box-shadow: 1px 1px 6px -2px #17256f;
`

export const VersionContainer = styled.span`
    color: ${props => props.theme.tertiaryText};
    position: absolute;
    left: 54px;
    top: 1px;
    font-size: 12px;
    border: 1px solid #315e0f;
    padding: 0px 5px;
    border-radius: 7px;
    background-color: #f7f7f7;
    font-weight: 600;
    box-shadow: 1px 1px 6px -4px black;
    color: #315e0f;
    z-index: 1;

`
