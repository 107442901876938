import React from 'react'
import { FallbackContainer, StyledLoading } from './styled'


const Fallback:React.FC<Record<string, never>> = (props) => {



    return <FallbackContainer>
        <StyledLoading size={35}/>
    </FallbackContainer>
}

export default Fallback