import { UserStyle } from "models/userStyles"
import { APIService } from "services/API"

const url = '/userStyles'

export const userStylesService = {

    getAllUserStylesByUserName: (userName:string) => {
        return APIService.get<UserStyle, UserStyle>(`${url}/free/${userName}`)
    },
    update: (userStyle: Partial<UserStyle>) =>{
        return APIService.put<UserStyle, UserStyle>(`${url}`, userStyle)
    }

}



