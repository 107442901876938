import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { linksReducer } from './links.store'
import { linksStatisticReducer } from './linksStatistic.store'
import { loginReducer } from './login.store'
import { userFeedbackReducer } from './userFeedback.store'
import { userImagesReducer } from './userImages.store'
import { usersReducer } from './users.store'
import { userStatisticReducer } from './userStatistic.store'
import { userStylesReducer } from './userStyles.store'


const combinedReducer = combineReducers({
    usersReducer,
    loginReducer,
    linksReducer,
    userImagesReducer,
    userStylesReducer,
    linksStatisticReducer,
    userFeedbackReducer,
    userStatisticReducer
});

const rootReducer = (state:any, action:any) => {
  if (action.type === 'users/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store